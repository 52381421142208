const links = [
    {
        label: 'Home',
        url  : '/',
    },
    {
        label: 'Sobre',
        url  : '/about',
    },
];

export default links;
